@import '../../../UI/global.module.scss';

.container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 56px;
	background-color: #FFFFFF;
	padding: 80px 80px 82px;

	.title {
		font-size: 45px;
		font-family: Roca One;
		font-weight: 400;
		line-height: 110%;
		letter-spacing: 2%;
		text-align: center;
	}

	.body {
		display: flex;
		flex-direction: column;
		gap: 64px;
		border-radius: 40px;
		background-color: #FFFFFF;
		padding: 8px 112px 0;
		max-width: 1278px;

		.slider {
			display: flex;
			flex-direction: column;
			gap: 64px;


			.containerCards {
				position: relative;
				display: grid;
				grid-template-columns: 1fr;
				
				.card {
					display: flex;
					flex-direction: row;
					gap: 32px;
		
			
					.image{
						width: 150px;
						height: 150px;
						border-radius: 20px;
					}
			
					.text {
						display: flex;
						flex-direction: column;
						gap: 24px;
	
						.containerStudent {
							display: flex;
							align-items: center;
							gap: 15px;
	
							.cardStudentName {
								font-size: 18px;
								font-family: NT Bau;
								font-weight: 400;
								line-height: 24px;
								letter-spacing: -0.5px;
							}
						}
		
						
						.cardDescription {
							font-size: 24px;
							font-family: NT Bau;
							font-weight: 400;
							line-height: 130%;
							letter-spacing: -2%;
							min-height: 125px;
						}
					}
				}

				.arrowLeft {
					border-radius: 50%;
					position: absolute;
					top: calc(50% - 32px);
					left: -112px;
					cursor: pointer;
				}
				
				.arrowRight {
					border-radius: 50%;
					position: absolute;
					top: calc(50% - 32px);
					right: -112px;
					cursor: pointer;
		
					& svg {
						transform: rotate(180deg);
					}
				}
			}
		}

	}
	.containerDots {
		display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 12px;

		.dot {
			width: 8px;
			height: 8px;
			border-radius: 50%;
			cursor: pointer;
		}
	}

	.button {
		width: 420px;
		height: 54px;
	}
}

.mainColor {
	color: $main-color;
}

.desktop {
	display: block;
}

.mobile {
	display: none;
}

@media only screen and (max-width: 768px) {
	.container {
		gap: 32px;
		padding: 32px 20px 40px;

		.title {
			font-size: 30px;
			line-height: 110%;
			letter-spacing: 0.6px;
			text-align: center;
		}
		.body {
			padding: 0 44px 0;
			border-radius: 12px;
			gap: 24px;

			.slider {
				gap: 32px;
				
				.containerCards {
		
					.card {
						flex-direction: column;
						gap: 8px;
						align-items: center;
				
						.text {
							
							.cardDescription {
								font-size: 18px;
								line-height: 24px;
								letter-spacing: -0.5px;
								min-height: 264px;
							}
	
							.containerStudent {
	
								.image{
									width: 40px;
									height: 40px;
									border-radius: 8px;
								}
	
								.cardStudentName {
									font-size: 14px;
									line-height: 20px;
									letter-spacing: -0.5px;
								}
							}
						}
					}

					.arrowLeft {
						position: absolute;
						top: calc(50% - 62px);
						left: - 60px;
						transform: scale(50%);
					}
				
					.arrowRight {
						position: absolute;
						top: calc(50% - 62px);
						right: - 60px;
						transform: scale(50%);
					}
				}
			}
		}
		
		.button {
			width: 100%;
			height: 48px;
		}
	}

	.desktop {
		display: none;
	}

	.mobile {
		display: block;
	}
}